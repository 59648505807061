import SecureLS from 'secure-ls'
const ls = new SecureLS();

export const Storage = {
    getAccount: () => {
        try {
            return ls.get(`${process.env.VUE_APP_NAME_ID}_account`)
        }catch(error){
            return null
        }
    },
    setAccount: (account = null) => {
        try {
            ls.set(`${process.env.VUE_APP_NAME_ID}_account`, account)
        } catch (error) {
            throw new Error(`Error trying to save Account: ${error}`)
        }
    },
    clear: () => {
        Storage.setAccount()
    }
}