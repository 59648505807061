import Vue from 'vue'
import Vuex from 'vuex'
import Axios from "@/utils/Axios";
import {Storage} from "@/utils/Storage";

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        //Users
        account: Storage.getAccount(),

        //Breadcrumbs
        breadcrumbs: null,

        //Dialog
        dialogActive: false,

        //Snackbar
        snackBarModel: false,
        snackBarText: '',
        snackBarColor: 'success',
        snackBarCloseColor: 'white',
    },
    mutations: {
        showSnackBar(state, message = '') {
            state.snackBarText = message.text || message
            state.snackBarColor = typeof(message.color) === 'string' && message.color || 'grey darken-3'
            state.snackBarCloseColor = message.closeColor || 'white'
            state.snackBarModel = true
        },
        setSnackBarModel(state, value) {
            state.snackBarModel = value
        },
        hideSnackBar(state) {
            state.snackBarModel = false
        },
        setDialogActive(state, active = false) {
            state.dialogActive = active
        },
        setAccount(state, account) {
            Storage.setAccount(account)
            Axios.setToken(account && account.api_token || null)
            state.account = account
        },
        setToken(token) {
            Axios.setToken(token)
        },
        setBreadcrumbs(state, breadcrumb = false) {
            state.breadcrumbs = breadcrumb
        },
    },
})
