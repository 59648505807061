<template>
    <div>
        <v-app-bar v-if="isMobile" dark app flat color="primary">
            <v-app-bar-nav-icon @click="drawer = !drawer"/>
            <v-spacer/>
            <v-img :src="require('@/assets/logo-square.png')" max-width="56"/>
        </v-app-bar>

        <v-navigation-drawer :permanent="!isMobile" v-model="drawer" app color="primary">
            <v-img :src="require('@/assets/logo-square.png')" max-width="150" class="mx-auto my-5"/>

            <v-list two-line width="80%" class="mx-auto">
                <v-list-item
                    v-for="item in items"
                    :key="item.title" link
                    :class="menuActive(item.to)"
                    :to="{name: item.to, params: {id: clientId}}"
                >
                    <v-list-item-content>
                        <v-list-item-title class="menu-item">{{ item.title }}</v-list-item-title>
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <template v-slot:append>
                <v-row>
                    <v-col cols="12" align="center" class="logout-col">
                        <v-btn class="logout-btn" color="accent" depressed @click="logout">
                            Salir
                        </v-btn>
                    </v-col>
                </v-row>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script>

import {mapMutations} from "vuex";
import Axios from "@/utils/Axios";

export default {
    name: 'Sidebar',
    data() {
        return {
            drawer: false,
        }
    },
    computed: {
        currentRoute(vm = this) {
            return vm.$route.name
        },
        items(vm = this) {
            if(vm.isClient) {
                return [
                    {title: 'Inicio', to: 'home'},
                    {title: 'Mis Documentos', to: 'clients.details'},
                    {title: 'Mi Cuenta', to: 'account'}
                ]
            } else if(vm.isAdmin) {
                return [
                    {title: 'Escritorio', to: 'home'},
                    {title: 'Clientes', to: 'clients'},
                    {title: 'Reuniones', to: 'meetings'},
                    {title: 'Tareas', to: 'tasks'},
                    {title: 'Presupuestos', to: 'budget'},
                    {title: 'Usuarios', to: 'users'},
                    {title: 'Carpetas', to: 'folders'},
                    {title: 'Cumpleaños', to: 'birthdays'},
                    {title: 'Mi Cuenta', to: 'account'}
                ]
            } else {
                return [
                    {title: 'Escritorio', to: 'home'},
                    {title: 'Clientes', to: 'clients'},
                    {title: 'Reuniones', to: 'meetings'},
                    {title: 'Tareas', to: 'tasks'},
                    {title: 'Presupuestos', to: 'budget'},
                    {title: 'Mi Cuenta', to: 'account'}
                ]
            }
        },
        clientId(vm = this) {
            return vm.account?.client_id || null
        }
    },
    methods: {
        ...mapMutations(['setAccount']),
        async logout() {
            const vm = this
            vm.loading = true

            await Axios.post('auth/logout').then(res => {
                // console.log('res logout',res)
            }).catch(error => {
                console.log('error logout', error)
            })

            vm.setAccount(null)
            await vm.$router.push({name: 'login'})
            vm.loading = false
        },
        toLogin() {
            let vm = this
            return vm.$router.push({name: 'login'})
        },
        menuActive(to) {
            const vm = this
            return to === vm.currentRoute.split('.')[0] ? 'menu-active' : ''
        }
    },
}

</script>

<style scoped>

.menu-active {
    background-color: var(--v-accent-base);
    border-radius: 2px;
}

.menu-item {
    color: #fff;
    text-align: center;
    font-size: 15px !important;
}

.logout-col {
    padding-top: 40px;
    margin-bottom: 30px;
}

.logout-btn {
    text-transform: none;
    width: 80%;
}

</style>