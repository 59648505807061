import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';

import VueMoment from 'vue-moment'

const moment = require('moment')
require('moment/locale/es')
Vue.use(VueMoment, {moment})

import { TiptapVuetifyPlugin } from 'tiptap-vuetify'
import 'tiptap-vuetify/dist/main.css'
Vue.use(TiptapVuetifyPlugin, {vuetify, iconsGroup: 'mdi'});

import './utils/Filters'

import {baseMixin} from '@/mixins/baseGlobalMixin'

Vue.mixin(baseMixin)

new Vue({
    router,
    store,
    vuetify,
    render: h => h(App)
}).$mount('#app')
