import {mapState, mapMutations} from 'vuex'

export const baseMixin = {
    computed: {
        ...mapState(['account']),
        isMobile() {
            return this.$vuetify.breakpoint.smAndDown
        },
        appName() {
            return process.env.VUE_APP_NAME || 'APP'
        },
        imgPlaceholder() {
            return require('@/assets/logo.png') || null
        },
        isAdmin() {
            return this.account?.role_id === 1 || false
        },
        isAmbUser() {
            return this.account?.role_id === 2 || false
        },
        isClient() {
            return this.account?.role_id === 3 || false
        }
    },
    methods: {
        ...mapMutations(['showSnackBar']),
        toast(message = '', color = 'success', closeColor = 'white') {
            const vm = this
            vm.showSnackBar({
                text: message,
                color: color,
                closeColor: closeColor
            })
        },
        timestampToDateTime(seconds) {
            if (!seconds) return null
            let date = new Date(1970, 0, 1) // Epoch
            date.setSeconds(seconds)
            return date
        },
        formatDate(date) {
            if (!date) return null
            const [year, month, day] = date.split('-')
            return `${day}-${month}-${year}`
        },
        clearDotsNumber(number) {
            if (!number) return null
            return Number(number.toString().replaceAll('.', ''))
        }
    },
}