import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css';
import es from 'vuetify/es5/locale/es';

Vue.use(Vuetify);

export default new Vuetify({
    theme: {
        options: {
            customProperties: true,
        },
        themes: {
            light: {
                primary: '#b92e29', // RED
                secondary: '#ede8e8', // GREY
                accent: '#ea3940', // RED LIGHT
                gold: '#cbba91', // GOLD
                background: '#f3f4f5', // GREY LIGHT
                backgroundColor: '#f3f4f5', // GREY LIGHT
                grey: '#767676'
            },
        },
    },
    lang: {
        locales: {es},
        current: 'es',
    },
});
