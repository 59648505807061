<template>
    <v-app>
        <sidebar v-if="sidebar"/>

        <v-main :class="[{'background': withBg}, 'background-color']">
            <toolbar v-if="toolbar"/>
            <router-view></router-view>
        </v-main>

        <kimsa-toast/>
        <kimsa-footer v-if="footer"/>
    </v-app>
</template>

<script>

import KimsaToast from "./components/common/KimsaToast";
import Toolbar from "@/components/common/Toolbar";
import KimsaFooter from "@/components/common/KimsaFooter";
import Sidebar from "@/components/common/Sidebar";

export default {
    name: 'App',
    components: {
        Sidebar,
        KimsaFooter,
        Toolbar,
        KimsaToast
    },
    computed: {
        hasMeta() {
            return !!this.$route.meta
        },
        toolbar() {
            return this.hasMeta && this.$route.meta.toolbar || false
        },
        sidebar() {
            return this.hasMeta && this.$route.meta.sidebar || false
        },
        footer() {
            return this.hasMeta && this.$route.meta.footer || false
        },
        withBg() {
            return this.$route.name === 'login' || this.$route.name === 'register' || this.$route.name === 'change-password'
        }
    }
};
</script>

<style>

.border-black {
    border: 1px solid black
}

.border-red {
    border: 1px solid red
}

.border-blue {
    border: 1px solid blue
}

.bg {
    background-image: url("assets/bg.jpg");
    height: 100%;
}

.title-section {
    display: inline-block;
    font-size: 20px;
    font-weight: bold;
    color: #333333;
    border-bottom: 3px solid var(--v-primary-base);
    width: fit-content;
}

.background {
    background-image: url("assets/background-new.jpg");
    background-repeat: no-repeat;
    background-position: 50% 0;
    background-size: cover;
}

.background-color {
    background-color: var(--v-background-base);
    font-family: 'Montserrat', sans-serif;
}

.normal-btn {
    text-transform: none !important;
    border-radius: 2px !important;
}

.hover-shadow:hover {
    box-shadow: 0 5px 10px 0 rgba(0,0,0,.2);
}

.accent-divider {
    background-color: var(--v-accent-base);
}

</style>
