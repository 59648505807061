<template>
    <v-footer padless height="100px">
        <v-card
            flat tile
            width="100%"
            class="text-center"
        >
            <v-card-text>
                <v-divider class="px-3"></v-divider>
                <v-img class="mx-auto mt-5" :src="imgPlaceholder" max-width="150"></v-img>
            </v-card-text>
        </v-card>
    </v-footer>
</template>

<script>
export default {
    name: "Footer",
}
</script>

<style scoped>

</style>