<template>
    <v-toolbar color="white" flat height="80" style="border-bottom: 2px solid #5f5f5f !important;">
        <v-img class="mx-auto" :src="imgLogo" max-width="200"/>
    </v-toolbar>
</template>

<script>

export default {
    computed: {
        imgLogo() {
            return require('@/assets/logo-red-black.png')
        },
        fullToolbar() {
            return this.$route.name !== 'videochat-room'
        }
    },
    methods: {
        toHome() {
            const vm = this
            if(vm.$route.name === 'home') return
            return vm.$router.push({name: 'home'})
        },
        toAdmin() {
            const vm = this
            // if(vm.$route.name === 'admin') return
            // return vm.$router.push({name: 'admin'})
        },
    },
}
</script>