import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

Vue.use(VueRouter)

/* COMIENZO DE PROYECTO EL 14 DE JULIO DE 2021 */

const routes = [
    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/entrance/Login'),
        meta: {
            toolbar: true,
        }
    },
    {
        path: '/change-password',
        name: 'change-password',
        component: () => import('@/views/entrance/ChangePassword'),
        meta: {
            toolbar: true,
        }
    },
    // {
    //     path: '/register',
    //     name: 'register',
    //     component: () => import('@/views/entrance/Register'),
    //     meta: {
    //         toolbar: true,
    //     }
    // },
    {
        path: '/account',
        name: 'account',
        component: () => import('@/views/Account/Account'),
        meta: {
            sidebar: true,
        }
    },
    {
        path: '/home',
        name: 'home',
        component: () => import('@/views/Home'),
        meta: {
            sidebar: true,
        }
    },
    {
        path: '/clients',
        name: 'clients',
        component: () => import('@/views/Clients/Clients'),
        meta: {
            sidebar: true,
        }
    },
    {
        path: '/clients/create',
        name: 'clients.create',
        component: () => import('@/views/Clients/ClientCreate'),
        meta: {
            sidebar: true,
        }
    },
    {
        path: '/clients/:id',
        name: 'clients.details',
        component: () => import('@/views/Clients/ClientDetails'),
        meta: {
            sidebar: true,
        }
    },
    {
        path: '/tasks',
        name: 'tasks',
        component: () => import('@/views/Tasks/Tasks'),
        meta: {
            sidebar: true,
        }
    },
    {
        path: '/meetings',
        name: 'meetings',
        component: () => import('@/views/Meetings/Meetings'),
        meta: {
            sidebar: true,
        }
    },
    {
        path: '/meetings/videochat',
        name: 'meetings.videochat',
        component: () => import('@/views/Meetings/Videochat'),
        meta: {
            sidebar: true,
        }
    },
    {
        path: '/financial',
        name: 'financial',
        component: () => import('@/views/Financial/Financial'),
        meta: {
            sidebar: true,
        }
    },
    {
        path: '/budget',
        name: 'budget',
        component: () => import('@/views/Budget/Budget'),
        meta: {
            sidebar: true,
        }
    },
    {
        path: '/users',
        name: 'users',
        component: () => import('@/views/Users/Users'),
        meta: {
            sidebar: true,
        }
    },
    {
        path: '/folders',
        name: 'folders',
        component: () => import('@/views/Documents/Folders'),
        meta: {
            sidebar: true,
        }
    },
    {
        path: '/vc/:link',
        name: 'videochat-room',
        component: () => import('@/views/Meetings/VideochatRoom'),
    },
    {
        path: '/birthdays',
        name: 'birthdays',
        component: () => import('@/views/Birthdays/Birthdays'),
        meta: {
            sidebar: true,
        }
    },
]

const router = new VueRouter({
    routes
})

router.beforeEach((to, from, next) => {
    // REPLACE ROUTE IF URL IS UNKNOWN
    if(to.name === null) {
        if (store.state.account) return next({name: 'home', replace: true})
        return next({name: 'login', replace: true})
    }

    if (store.state.account) {
        let role = store.state.account?.role_id || null
        if(role === 3 && to.name === 'clients')
            return next(false)
    }

    // CLOSE ACTIVE DIALOG
    if (store.state.dialogActive) {
        store.state.dialogActive = false
        return next(false)
    }

    return next()
})

export default router
