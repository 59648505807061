<template>
    <v-snackbar v-model="snackBar" :color="snackBarColor" top right multi-line :timeout="3000">
        {{ snackBarText }}
        <template v-slot:action="{ attrs }">
            <v-btn :color="snackBarCloseColor" text v-bind="attrs" @click.stop="hideSnackBar">
                Cerrar
            </v-btn>
        </template>
    </v-snackbar>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
    name: "KimsaToast",
    computed: {
        ...mapState([
            'snackBarModel',
            'snackBarText',
            'snackBarColor',
            'snackBarCloseColor',
        ]),
        snackBar: {
            get() {
                return this.snackBarModel
            },
            set(val) {
                this.setSnackBarModel(val)
            }
        },
    },
    methods: {
        ...mapMutations(['hideSnackBar', 'setSnackBarModel',])
    }
}
</script>

<style scoped></style>