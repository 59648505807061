import axios from "axios";
import store from '../store'
import router from '../router'
const baseUrl = process.env.VUE_APP_BASE_URL_API || null

const api = axios.create({
    baseURL: baseUrl,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'Content-Type, Authorization, X-Requested-With, X-API-KEY',
    }
});

api.interceptors.response.use(response => {
    return response;
}, async error => {
    if (401 === error?.response?.status) {
        await router.replace({name: 'login'})
        return Promise.resolve(error.response);
    } else {
        return Promise.reject(error);
    }
});

export default {
    async setToken(token) {
        if(!token) {
            if(store.state.account.api_token)
                api.defaults.headers.common['X-API-KEY'] = store.state.account.api_token
            else
                if(router?.currentRoute?.name !== 'login') await router.replace({name: 'login'})
        } else {
            api.defaults.headers.common['X-API-KEY'] = token
        }
    },
    get(url = '') {
        if(!api.defaults.headers.common['X-API-KEY']) this.setToken()
        return api.get(url)
    },
    post(url = '', params = {}, headers = {}) {
        if(!api.defaults.headers.common['X-API-KEY']) this.setToken()
        return api.post(url, params, headers);
    },
    patch(url = '', params = {}, headers = {}) {
        if(!api.defaults.headers.common['X-API-KEY']) this.setToken()
        return api.patch(url, params, headers)
    },
    delete(url = '') {
        if(!api.defaults.headers.common['X-API-KEY']) this.setToken()
        return api.delete(url);
    },
    getFile(url = '', ) {
        if(!api.defaults.headers.common['X-API-KEY']) this.setToken()
        return api.get(url, {responseType: 'blob'})
    },
};