import Vue from 'vue'

const moment = require('moment')
require('moment/locale/es')

Vue.filter('uppercase', (value) => {
    if (!value) return ''
    return value.toString().toUpperCase()
})

Vue.filter('capitalize', (value) => {
    if (!value) return ''
    const val = value.toString()
    return `${val[0].toUpperCase()}${val.substr(1)}`
})

Vue.filter('date_splitter', (value) => {
    let data = value.split(' ')
    const date = moment(data[0], 'YYYY-MM-DD').format('D MMM')
    return `${date} a las ${data[1].substr(0, 5)}`
})

Vue.filter('phone_format', (value) => {
    if (!value) return '---'
    const v = value.split('')
    const a = v[0] + v[1]
    const b = v[2]
    const c = v[3] + v[4] + v[5] + v[6]
    const d = v[7] + v[8] + v[9] + v[10]
    return `+(${a}) ${b} ${c} ${d}`
})

Vue.filter('number', (number) => {
    if (!number) return ''
    return number.toString()
        .replace(/^0/, '')
        .replace(/\./, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")
})

Vue.filter('phone_format_short', (value) => {
    if (!value) return '---'
    const v = value.split('')
    const a = v[0]
    const b = v[1] + v[2] + [3] + v[4]
    const c = v[5] + v[6] + v[7] + v[8]
    return `+(56) ${a} ${b} ${c}`
})

Vue.filter('money', (value) => {
    return Number(value).toLocaleString('es')
})

Vue.filter('rut', function (value) {
    if (!value) return ''
    if(value.length === 1) return value

    var clean = value.replace(/\./g, '')
        // eslint-disable-next-line no-useless-escape
        .replace(/\-/g, '')

    if(clean.length > 9) clean = clean.substring(0, 9)

    const firstSection = clean.substring(0, clean.length-1).toString()
        .replace(/\./g, '')
        .replace(/\B(?=(\d{3})+(?!\d))/g, ".")

    const dv = clean.substring(clean.length-1, clean.length)

    return `${firstSection}-${dv.toUpperCase()}`
})
